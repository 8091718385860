<template>
  <div>
    <!--begin::Tables Widget 9-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Danışan Listesi</span>

        <span class="text-muted mt-1 fw-bold fs-7">Toplam <b>{{recordcount}}</b> Danışan</span>
      </h3>

      
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      
      <Filter @filter="filter($event)" />

      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover"
        >
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              
              <th class="">ID</th>
              <th class="min-w-150px">Adı Soyadı</th>
              <th>Telefon/Email</th>
              
              <th class="min-w-120px">İşletim Sistemi</th>
              <th class="min-w-100px">Kayıt Tarihi</th>
              <th>Cinsiyet</th>
              <th>Hemen Görüşme Hakkı</th>
              <th>Randevulu Görüşme Hakkı</th>
              
              <th>ETK İzni</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody v-if="list">
            <template v-for="(item, index) in list" :key="index">
              <tr>
                <td>
                  {{item.userId}}
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-45px me-5" v-if="item.profileImage && item.profileImage!='avatar.jpg'">
                      <img :src="'https://api.ytmdanismanlik.com/'+item.profileImageDirectory+item.profileImage" alt="" />
                    </div>
                    
                    <div class="d-flex justify-content-start flex-column">
                      <a :href="'client/'+item.userId+'/calls'" class="text-dark fw-bolder text-hover-primary fs-6">{{ item.name }} {{ item.surname }} 
                        <span :class="'badge badge-light-'+statusses.class[item.status]">{{statusses.label[item.status]}}</span></a>
                      <span class="text-muted fw-bold text-muted d-block fs-7">
                       <div class="d-flex align-items-center">
                         <country-flag :country="item.countryIso" size='small' />
                        <span>{{item.niceName}}</span>
                        
                       </div>
                       
                        </span>
                    </div>
                    
                    
                    
                  </div>
                </td>

                <td>
                  <a class="text-dark fw-bolder text-hover-primary d-block fs-6"> +{{item.phonePrefix}}{{ item.phone }}</a>
                    <span class="text-muted fw-bold text-muted d-block fs-7">{{item.email}}</span>
                </td>

                

                <td>
                  <div class="d-flex justify-content-start flex-column text-dark fw-bolder text-hover-primary d-block fs-6">
                      {{ item.mobileOs }}
                      <span class="text-muted fw-bold text-muted d-block fs-7">{{item.mobileOsversion}}

                        <span v-if="item.appVersion">
                          - Uygulama Versiyonu: {{item.appVersion}}
                        </span>
                      </span>
                       
                    </div>
                </td>

                <td>
                  <div class="d-flex justify-content-start flex-column text-dark fw-bolder text-hover-primary d-block fs-6">
                      {{moment(item.createdDate).format('DD MMMM YYYY')}}
                      <span class="text-muted fw-bold text-muted d-block fs-7">
                        {{registerChannels[item.registerChannelId]}} {{moment(item.createdDate).format('HH:MM')}}
                        </span>
                       
                    </div>
                  
                </td>
                <td>
                  <div class="d-flex justify-content-start flex-column text-dark fw-bolder text-hover-primary d-block fs-6">
                      {{item.gender ? genders[item.gender] : ''}}
                      <span class="text-muted fw-bold text-muted d-block fs-7" v-if="item.birthday">
                        {{Math.round(moment().diff(item.birthday,'years',true))}} Yaş
                        </span>
                       
                    </div>
                </td>


                <td>
                  <div class="text-dark fw-bolder text-hover-primary d-block fs-6">
                      {{item.usageCount.poolVCCount}}
                    </div>
                </td>

                <td>
                  <div class="text-dark fw-bolder text-hover-primary d-block fs-6">
                        {{item.usageCount.appVCCount}}
                    </div>
                </td>

                <td>
                  <span :class="'badge badge-light-'+(item.advertisementFlag ? 'success' : 'danger')">{{item.advertisementFlag ? 'Evet' : 'Hayır'}}</span>
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <paging 
            v-if="recordcount>0" 
            :page="page" 
            :pagelength="pagelength" 
            :recordcount="recordcount" 
            @next="next" 
            @prev="prev"
            @gotoPage="gotoPage($event)"
            />
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Tables Widget 9-->





  </div>
</template>

<script>
import api from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CountryFlag from 'vue-country-flag-next'
import Filter from '@/components/filters/ClientListFilter.vue'
import Paging from '@/components/BD/SelectPaging.vue'

import moment from 'moment'

export default {
  name: "Clients",
  components:{
    Paging,
    CountryFlag,
    Filter
  },
  data(){
    return {
      clientFilter:{
          status:'JJ',
          user_status:'JJ',
          filter_word:'',
          sorting_type:2,
          
         
      }, 
      selectedUser:null,
       packageId:null,
       packages:null,
      list:null,
      recordcount:null,
       
      genders:{
        'M':'Erkek',
        'F':'Kadın'
      },
      statusses : {
        class:{
          'AC':'success',
          'DA':'danger'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif'

        }
      },
      
      pagelength:25,
      page:1,

      registerChannels : {
        1:'Uygulama, Saat:',
        0:'Web, Saat:'
      }
    }
  },
  watch:{
      page:function(){
        this.getClientsList();
      }
    },
  methods:{

    showPackagesForm(user){
      this.selectedUser = user;
    },

    
    filter(filterObj){
      this.clientFilter = filterObj;
      this.getClientsList();
    },

    moment(date){
      return moment(date);
    },
     getClientsList(){
      let paging = {
        pagelength:this.pagelength,
        page:this.page
      };

      let filter = this.clientFilter;

      
      let apiUrl = '/User/ListUsers/'+filter.status+'/10/0/'+paging.pagelength +"/" +paging.page +"/"+(filter.filter_word=='' ? '-' : filter.filter_word) +"/"+filter.sorting_type+"/"+filter.user_status;

      api.get(apiUrl).then((res)=>{
        this.recordcount = res.data.rowCount;
         this.list=res.data.listUser;
      })
    },

    next(){
        this.page++;
      },

      prev(){
        if(this.page>1){
          this.page--;
        }
      },

      gotoPage(pageNo){
        this.$router.push('?page='+pageNo);        
        this.page = pageNo;
      }
  },
  mounted(){
   
    this.getClientsList();
    setCurrentPageBreadcrumbs("Danışan Listesi", ["Danışanlar"]);
  }
  
  }
</script>
