<template>
  <div>
      <form action="">
      <div class="d-flex border-bottom pb-9 mb-9 align-items-end" v-if="filter">
           <div class="me-4">
              Durum
              <select name="" v-model="filter.status" id="" class="form-select" >
                  <option v-for="(status, i) in statuses" :key="i" :value="i">{{status}}</option>
              </select>
           </div>
           <div class="me-4">
              Üyelik Durumu
              <select name="" v-model="filter.user_status" id="" class="form-select" >
                  <option v-for="(status, i) in user_statuses" :key="i" :value="i">{{status}}</option>
              </select>
           </div>
           <div class="me-4">
              Arama
              <input type="text" class="form-control" v-model="filter.filter_word">
             
           </div>
           <div class="me-4">
               Sıralama
                <select name="" v-model="filter.sorting_type" id="" class="form-select" >
                    <option v-for="(status, i) in sorting_types" :key="i" :value="i">{{status}}</option>
                </select>
           </div>
           <div>
                <input @click.prevent="sendFilter()" type="submit" class="btn btn-info" value="Filtere">
           </div>
      </div>
      </form>
  </div>
</template>

<script>
export default {
    name:'ClientListFilter',
    props:['defaultFilter'],
    data(){
        return {
           filter:{
                status:'JJ',
                user_status:'JJ',
                filter_word:'',
                sorting_type:2
            },

            statuses:{
                'JJ':'Tümü',
                'AC':'Aktif',
                'DA':'Pasif'
            },

            user_statuses:{
                'JJ':'Tümü',
                'AC':'Tamamlanmış',
                'DA':'Henüz Tamamlanmamış'
            },

            sorting_types:{
                1:'Tarih Eskiden Yeniye',
                2:'Tarih Yeniden Eskiye',
                3:'İsim A-Z',
                4:'İsim Z-A',
                5:'E-Posta Adresine Göre A-Z',
                6:'E-Posta Adresine Göre Z-A',
            }
        }
    },
    
    methods:{
        sendFilter:function(){
            this.$emit("filter",this.filter);
        }
    },

   
}
</script>

<style>

</style>