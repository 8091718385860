<template>
   <ul class="pagination pagination m-0 float-right" :class="pageCount>10 ? 'pagination-groupped' : 'pagination-link'">
       

        <li class="page-item" v-if="page>1"><a class="btn btn-primary" href="#" @click.prevent="prev"> &laquo; Önceki sayfa</a></li>
        
        <li>
            <select name="" class="form-select" id="" @change="changePageNo($event)">
                <option value="">--</option>
                <option :selected="page==pageNo" :value="pageNo" v-for="pageNo in pageCount" :key="pageNo">{{pageNo}}</option>
            </select>
        </li>
        
        
        <li class="page-item" v-if="page<pageCount"><a class="ms-2 btn btn-primary" href="#" @click.prevent="next">Sonraki sayfa &raquo;</a></li>
    </ul>
</template>

<script>
export default {
    props:['recordcount','pagelength','page'],
    name:'SelectPaging',

    data(){
        return {
            pageNumberItems: []      
        }
    },
   

    methods:{
        next(){
            if(this.page<this.pageCount){
                this.$emit("next");
            }
            
        },
        prev(){
            if(this.page>1){
                this.$emit("prev");
            }
            
        },
        gotoPage(pageNo){
            this.$emit('gotoPage',pageNo);
        },

        changePageNo(event){
            this.gotoPage(event.target.value);
        }
    },
    computed:{
        pageCount(){
            
            let pageCount =  Math.ceil(this.recordcount / this.pagelength);
            let clusterStart = ((this.page - 1) * 10) / 10;
            for(let i=0;i<pageCount;i++){
                this.pageNumberItems.push(i+1);
            }

            console.log(clusterStart);
            
            return pageCount;
        }
    }

    

}
</script>

